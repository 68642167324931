import { getYearsFromDateRange } from './period'
import { formatValue } from '~/services/format'
import {
  compareValuesAlphabetically,
  getFormattedDimensionValue
} from '~/services/analytics'
import {
  getCubeFromMeasureOrDimensionName,
  getDimensionFromTitleAndCube,
  getDimensionTranslation,
  getMainCubeFromQuery,
  getMeasureFromTitle
} from '~/services/explore'
import { MemberFormat, type Dimension, type Measure } from '~/types/cube'
import type { DimensionValue, DimensionWithValue } from '~/types/analytics'
import type { ResultSet, ResultSetItem } from '~/types/query'
import type { TableCell, TableData, TableNode } from '~/types/table'
import {
  TABLE_ATTRIBUTES,
  TABLE_CELL_ATTRIBUTES,
  TABLE_ROW_ATTRIBUTES
} from '~/constants/bdese'
import type { BdeseQuery } from '~/types/bdese'

export const getCustomTableData = (
  resultSet: ResultSet,
  measureTitle: string,
  rowDimensionTitles: string[],
  columnDimensionTitles: string[],
  defaultColumnValues: string[] | null,
  displayValuesAsPercent: boolean = false,
  displayTotal: boolean = false
): TableData => {
  const rawResultSetItems = resultSet.results
    .map(results => results.data)
    .flat()

  const measure = getMeasureFromTitle(measureTitle)
  const filteredResultsData = rawResultSetItems.filter(
    resultSetItem => resultSetItem[measure.name] !== null
  )

  const cube = getCubeFromMeasureOrDimensionName(measure.name)
  const rowDimensions = rowDimensionTitles.map(
    dimensionTitle => getDimensionFromTitleAndCube(dimensionTitle, cube)!
  )

  const columnDimensions = columnDimensionTitles.map(
    dimensionTitle => getDimensionFromTitleAndCube(dimensionTitle, cube)!
  )
  const dimensions = [...columnDimensions, ...rowDimensions]

  // If results data is empty we add one fake empty data to create an empty table
  const resultsData =
    filteredResultsData.length > 0
      ? filteredResultsData
      : [
          [...dimensions, measure].reduce(
            (fakeResultSet, member) => ({
              ...fakeResultSet,
              [member.name]: null
            }),
            {}
          )
        ]

  const columnValues =
    columnDimensions.length > 0
      ? getColumnValues(columnDimensions[0], resultsData, defaultColumnValues)
      : [undefined]

  const headerRows =
    columnDimensions.length > 0
      ? [
          [
            ...rowDimensions.map(dimension =>
              getTableCell(getDimensionTranslation(dimension), [], true)
            ),
            ...columnValues.map(value => {
              const formattedValue = getFormattedDimensionValue(
                value,
                columnDimensions[0]!
              )

              return getTableCell(
                formattedValue,
                [
                  [
                    columnDimensions[0]!,
                    value as Exclude<typeof value, undefined>
                  ]
                ],
                true,
                {
                  initialValue: formattedValue
                }
              )
            })
          ]
        ]
      : []

  const rowsWithDimensionValues = getRowsWithDimensionValues(
    resultsData,
    rowDimensions
  )

  const bodyRows = buildBodyRowsData(
    rowsWithDimensionValues,
    rowDimensions,
    headerRows?.[0] || [],
    columnDimensions,
    measure,
    resultsData,
    displayValuesAsPercent
  )

  if (displayTotal) {
    const { $t } = useNuxtApp()

    const total = columnValues.map(() => 0)

    getRowsWithDimensionValues(resultsData, rowDimensions).forEach(
      rowWithDimensionValues => {
        columnValues.forEach((columnValue, i) => {
          const cellDimensionValues = [
            ...(columnValue !== undefined ? [columnValue] : []),
            ...rowWithDimensionValues
          ]

          const matchingResultSetItem = getMatchingResultSetItem(
            cellDimensionValues,
            resultsData,
            dimensions
          )

          total[i]! += matchingResultSetItem?.[measure.name]
            ? parseFloat(matchingResultSetItem[measure.name] as string)
            : 0
        })
      }
    )

    const totalRow = [
      getTableCell($t('explore.options.total'), [], true, {
        colSpan: rowDimensions.length.toString()
      }),
      ...total.map(value => {
        const formattedValue = displayValuesAsPercent
          ? formatValue(100, MemberFormat.PERCENT, false)
          : formatValue(value, measure.meta.format, false)

        return getTableCell(formattedValue, [], false)
      })
    ]

    return { headerRows, bodyRows, totalRow }
  }

  return { headerRows, bodyRows, totalRow: null }
}

export const buildBodyRowsData = (
  rowsWithDimensionValues: DimensionValue[][],
  rowDimensions: Dimension[],
  mainHeaderRow: TableCell[],
  columnDimensions: Dimension[],
  measure: Measure,
  resultsData: ResultSetItem[],
  displayValuesAsPercent: boolean = false
): TableCell[][] => {
  const bodyRows: TableCell[][] = []
  const dimensions = [...columnDimensions, ...rowDimensions]

  const headerCellsWithValue = mainHeaderRow.slice(
    rowDimensions.length,
    mainHeaderRow.length
  )

  rowsWithDimensionValues.forEach(rowWithDimensionValues => {
    const bodyRow = [] as TableCell[]

    if (rowDimensions.length > 0) {
      rowDimensions.forEach((rowDimension, rowDimensionIndex) => {
        const formattedDimensionValue = getFormattedDimensionValue(
          rowWithDimensionValues[rowDimensionIndex]!,
          rowDimension
        )

        bodyRow.push(
          getTableCell(
            formattedDimensionValue,
            [[rowDimension, rowWithDimensionValues[rowDimensionIndex]!]],
            true,
            {
              initialValue: formattedDimensionValue
            }
          )
        )
      })
    }

    if (headerCellsWithValue.length > 0) {
      headerCellsWithValue.forEach(cell => {
        // check if cell is from user-created column
        if (cell?.attributes?.initialValue === undefined) {
          bodyRow.push(getTableCell(''))
        } else {
          const cellDimensionValues = [
            cell.attributes.initialValue,
            ...rowWithDimensionValues
          ]

          const matchingResultSetItem = getMatchingResultSetItem(
            cellDimensionValues,
            resultsData,
            dimensions
          )

          const dimensionsWithValue = getDimensionsWithValue(
            cellDimensionValues,
            dimensions
          )

          const value = getMeasureValue(
            matchingResultSetItem,
            measure,
            columnDimensions,
            resultsData,
            displayValuesAsPercent
          )

          bodyRow.push(
            getTableCell(value, dimensionsWithValue, false, {
              initialValue: value.toString()
            })
          )
        }
      })
    } else {
      const matchingResultSetItem = getMatchingResultSetItem(
        rowWithDimensionValues,
        resultsData,
        dimensions
      )

      const dimensionsWithValue = getDimensionsWithValue(
        rowWithDimensionValues,
        dimensions
      )

      const value = getMeasureValue(
        matchingResultSetItem,
        measure,
        columnDimensions,
        resultsData,
        displayValuesAsPercent
      )

      bodyRow.push(
        getTableCell(value, dimensionsWithValue, false, {
          initialValue: value.toString()
        })
      )
    }
    bodyRows.push(bodyRow)
  })

  return bodyRows
}

export const addColumnsData = (
  tableData: TableData,
  newColumnValues: DimensionValue[],
  columnDimensions: Dimension[],
  rowDimensions: Dimension[],
  measure: Measure,
  resultsData: ResultSetItem[],
  displayValuesAsPercent: boolean = false
): TableData => {
  let newHeaderRows: TableCell[][] = tableData.headerRows
  let newBodyRows: TableCell[][] = tableData.bodyRows
  const dimensions = [...columnDimensions, ...rowDimensions]

  const rowDimensionValues = tableData.bodyRows.map(row =>
    row
      .slice(0, rowDimensions.length)
      .map(cell => cell.dimensionsWithValue?.[0]?.[1])
  )

  newColumnValues.forEach(colValue => {
    // header
    newHeaderRows = [
      [
        ...newHeaderRows[0]!,
        getTableCell(
          getFormattedDimensionValue(
            colValue as Exclude<typeof colValue, undefined>,
            columnDimensions[0]!
          ),
          [
            [
              columnDimensions[0]!,
              colValue as Exclude<typeof colValue, undefined>
            ]
          ],
          true,
          {
            initialValue: getFormattedDimensionValue(
              colValue as Exclude<typeof colValue, undefined>,
              columnDimensions[0]!
            )
          }
        )
      ]
    ]

    // body
    newBodyRows = [
      ...newBodyRows.map((row, index) => {
        const isUserCreatedRow = row.every(
          cell => !cell.attributes?.initialValue
        )
        let tableCell: TableCell

        if (!isUserCreatedRow) {
          const cellDimensionValues = [
            colValue,

            ...(rowDimensionValues.length ? rowDimensionValues[index]! : [])
          ] as DimensionValue[]

          const dimensionsWithValue = getDimensionsWithValue(
            cellDimensionValues,
            dimensions
          )

          const matchingResultSetItem = getMatchingResultSetItem(
            cellDimensionValues,
            resultsData,
            dimensions
          )

          const value = getMeasureValue(
            matchingResultSetItem,
            measure,
            columnDimensions,
            resultsData,
            displayValuesAsPercent
          )

          tableCell = getTableCell(value, dimensionsWithValue, false, {
            initialValue: value.toString()
          })
        } else {
          tableCell = getTableCell()
        }

        return [...row, tableCell]
      })
    ]
  })

  return { headerRows: newHeaderRows, bodyRows: newBodyRows }
}

export const getTableCell = (
  content: string | null = null,
  dimensionsWithValue: DimensionWithValue[] = [],
  isHeader: boolean = false,
  attributes:
    | { colSpan?: string; initialValue?: string }
    | undefined = undefined
): TableCell => {
  return { isHeader, content, dimensionsWithValue, attributes }
}

export const getDefaultColumnValue = (
  isYearTable: boolean,
  dateRange: string[]
) => {
  if (!isYearTable) return null
  return getYearsFromDateRange(new Date(dateRange[0]!), new Date(dateRange[1]!))
}

export const getColumnValues = (
  columnDimension: Dimension | undefined,
  resultsData: ResultSetItem[],
  defaultColumnValues: string[] | null
) => {
  if (defaultColumnValues) return defaultColumnValues
  if (!columnDimension) return []

  const uniqueColumnValues = Array.from(
    new Set(
      resultsData.map(resultSetItem =>
        getDimensionValueFromResultSetItem(resultSetItem, columnDimension!)
      )
    )
  )

  return uniqueColumnValues.toSorted(compareValuesAlphabetically)
}

export const getDimensionValueFromResultSetItem = (
  resultSetItem: ResultSetItem,
  dimension: Dimension
): DimensionValue => {
  return resultSetItem[dimension.name]?.toString() ?? null
}

export const getRowsWithDimensionValues = (
  resultsData: ResultSetItem[],
  rowDimensions: Dimension[]
): DimensionValue[][] => {
  const allDimensionValues = resultsData.map(resultSetItem =>
    rowDimensions.map(dimension =>
      getDimensionValueFromResultSetItem(resultSetItem, dimension)
    )
  )

  const uniqueRowValues = allDimensionValues.reduce(
    (uniqueRowValues, curentDimensionRow) => [
      ...uniqueRowValues,
      ...(uniqueRowValues.some(dimensionRow =>
        dimensionRow.every(
          (value, index) => value === curentDimensionRow[index]
        )
      )
        ? []
        : [curentDimensionRow])
    ],
    [] as DimensionValue[][]
  )

  return rowDimensions.reduce(
    (sortedDimensionValues, _, dimensionIndex) =>
      sortedDimensionValues.toSorted((dimensionRow1, dimensionRow2) =>
        compareValuesAlphabetically(
          dimensionRow1[rowDimensions.length - 1 - dimensionIndex]!,
          dimensionRow2[rowDimensions.length - 1 - dimensionIndex]!
        )
      ),
    uniqueRowValues as DimensionValue[][]
  )
}

const getMatchingResultSetItem = (
  cellDimensionValues: DimensionValue[],
  resultData: ResultSetItem[],
  dimensions: Dimension[]
) => {
  return resultData.find(resultSetItem =>
    dimensions.every(
      (dimension, dimensionIndex) =>
        getDimensionValueFromResultSetItem(resultSetItem, dimension) ===
        cellDimensionValues[dimensionIndex]
    )
  )
}

const getDimensionsWithValue = (
  cellDimensionValues: DimensionValue[],
  dimensions: Dimension[]
) => {
  return dimensions.map(
    (dimension, index) =>
      [dimension, cellDimensionValues[index]] as DimensionWithValue
  )
}

const getMeasureValue = (
  matchingResultSetItem: ResultSetItem | undefined,
  measure: Measure,
  columnDimensions: Dimension[],
  resultsData: ResultSetItem[],
  displayValuesAsPercent: boolean
) => {
  if (displayValuesAsPercent) {
    return getMeasurePercentValue(
      matchingResultSetItem,
      measure,
      columnDimensions,
      resultsData
    )
  } else {
    return getMeasureDefaultValue(matchingResultSetItem, measure)
  }
}

const getMeasureDefaultValue = (
  matchingResultSetItem: ResultSetItem | undefined,
  measure: Measure
) => {
  return formatValue(
    matchingResultSetItem ? matchingResultSetItem[measure.name]! : null,
    measure.meta.format,
    false
  )
}

const getMeasurePercentValue = (
  matchingResultSetItem: ResultSetItem | undefined,
  measure: Measure,
  columnDimensions: Dimension[],
  resultsData: ResultSetItem[]
) => {
  const resultsDataTotal = resultsData.reduce(
    (total, item) =>
      isNaN(item[measure.name] as number)
        ? total
        : total + (item[measure.name] as number),
    0
  )

  const resultsDataTotalByColumnValues =
    columnDimensions.length > 0
      ? resultsData.reduce<{ [key: string]: number }>((total, item) => {
          return isNaN(item[measure.name] as number)
            ? total
            : {
                ...total,
                [item[columnDimensions[0]!.name] as string]:
                  (total[item[columnDimensions[0]!.name] as string] ?? 0) +
                  (item[measure.name] as number)
              }
        }, {})
      : undefined

  const total =
    columnDimensions.length > 0
      ? resultsDataTotalByColumnValues![
          matchingResultSetItem?.[columnDimensions[0]!.name] as string
        ]
      : resultsDataTotal

  return formatValue(
    matchingResultSetItem && total
      ? ((matchingResultSetItem[measure.name] as number) / total) * 100
      : 0,
    MemberFormat.PERCENT,
    false
  )
}

export const getContent = (tableCell: TableCell): string => {
  return tableCell.content || ''
}

export const getTableDataFromEditor = (tableNode: TableNode) => {
  const isYearTable = !!tableNode.attrs[TABLE_ATTRIBUTES.YEAR_TABLE_WITH_TREND]

  const query = JSON.parse(
    tableNode.attrs[TABLE_ATTRIBUTES.QUERY]!
  ) as BdeseQuery

  const cube = getMainCubeFromQuery({ measures: [query.measure] })!

  return tableNode.content.reduce<TableData & { isYearTable: boolean }>(
    (acc, row) => {
      const rowData = row.content.map(cell => {
        const content = cell.content?.[0]?.content?.[0]?.text
        const isHeader = cell.type === 'tableHeader'

        const dimensionsWithValue = cell.attrs[
          TABLE_CELL_ATTRIBUTES.DIMENSIONS_WITH_VALUE
        ]
          ? (JSON.parse(
              cell.attrs[TABLE_CELL_ATTRIBUTES.DIMENSIONS_WITH_VALUE]!
            ).map((dimensionWithValue: [string, string]) => [
              getDimensionFromTitleAndCube(dimensionWithValue[0], cube),
              dimensionWithValue[1]
            ]) as DimensionWithValue[])
          : undefined

        return getTableCell(content, dimensionsWithValue, isHeader, {
          ...(cell.attrs.colspan
            ? { colSpan: cell.attrs.colspan.toString() }
            : {}),
          ...(cell.attrs[TABLE_CELL_ATTRIBUTES.INITIAL_VALUE]
            ? {
                initialValue: cell.attrs[TABLE_CELL_ATTRIBUTES.INITIAL_VALUE]
              }
            : {})
        })
      })

      if (row.attrs[TABLE_ROW_ATTRIBUTES.IS_HEADER_ROW]) {
        acc.headerRows.push(rowData)
      } else if (row.attrs[TABLE_ROW_ATTRIBUTES.IS_TOTAL_ROW]) {
        acc.totalRow = rowData
      } else {
        acc.bodyRows.push(rowData)
      }
      return acc
    },
    { headerRows: [], bodyRows: [], totalRow: null, isYearTable }
  )
}
